.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms ease;
}

.slow-fade-enter {
  opacity: 0;
}
.slow-fade-enter-active {
  opacity: 1;
  transition: opacity 800ms ease;
}
.slow-fade-exit {
  opacity: 1;
}
.slow-fade-exit-active {
  opacity: 0;
  transition: opacity 800ms ease;
}